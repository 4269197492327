<template>
  <div id="result-component">
    <ProgressBar />
    <b-overlay
      :show="showLoading || (isEmpty(resultSearchFlight) && !statusJobDone)"
      spinner-variant="warning"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div>
        <HeaderTabFlight />
        <b-tabs
          v-model="tabIndex"
          pills
          nav-class="tab-title"
          nav-wrapper-class="nav-wrapper-class"
        >
          <b-tab
            v-for="(itinerary, indexItinerary) in resultSearchFlight"
            :key="indexItinerary"
          >
            <ItineraryTab
              :itinerary="itinerary"
              :index-itinerary="indexItinerary"
              :selected-trip.sync="selectedTrips[indexItinerary]"
              @update:itineraryToRender="$event => itineraryToRenders[indexItinerary] = $event"
              @update:isRenderArray="$event => isRenderArrays[indexItinerary] = $event"
              @clear="clearSelectedTrip"
            />
          </b-tab>

          <!-- ANCHOR Combination tab -->
          <b-tab
            v-if="getGroupedItineraryResponse"
            :active="getGroupedItineraryResponse"
          >
            <CombinationTab
              :is-loading="!statusJobDone"
              :selected="combinationSelectedTrip"
              :render-list="combinationTripList"
              @clear="clearSelectedTrip"
            />
          </b-tab>
        </b-tabs>
      </div>
      <!-- ANCHOR Không tìm thấy kết quả -->
      <div v-if="isEmpty(resultSearchFlight) && statusJobDone">
        <b-alert
          show
          variant="warning"
          class="mb-50 p-1"
        >
          {{ $t('flight.notBookingFound') }}
        </b-alert>
      </div>
      <Footer
        :selected-trips.sync="selectedTrips"
        :disabled-copy-price="showLoading || !statusJobDone || isEmpty(resultSearchFlight)"
      />

      <!-- FIXME báo giá kết hợp đang ko apply filter: `combinationTripList` -->
      <ModalQuote
        v-if="!(showLoading || !statusJobDone || isEmpty(resultSearchFlight))"
        :segment-tab-index="tabIndex"
        :selected-trips="selectedTrips"
        :combination-selected-trip="combinationSelectedTrip?.journeys ?? []"
        :title-arr="getSearchFlightArray"
        :trips-arr="itineraryToRenders"
        :is-render-trips-arr="isRenderArrays"
        :is-combination="isCombination"
        :combination-trip-list="combinationTripListForPriceReport"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BTab, BTabs, BOverlay, BAlert,
} from 'bootstrap-vue'
import {
  computed, ref, watch, watchEffect,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import store from '@/store'
import { BUSINESS_FILTER_REGEX } from '@/constants/flight'

import {
  convertISODateTime,
  formatCurrency,
  toHoursAndMinutes, resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useBookingHandle, { combinateFareOptions } from '@flightv2/useBookingHandle'

export default {
  components: {
    BTab,
    BTabs,
    BOverlay,
    BAlert,
    ModalQuote: () => import('./components/ModalQuote.vue'),
    Footer: () => import('./footer.vue'),
    ProgressBar: () => import('./components/ProgressBar.vue'),
    ItineraryTab: () => import('./components/ItineraryTab.vue'),
    CombinationTab: () => import('./components/combination/CombinationTab.vue'),
    HeaderTabFlight: () => import('./components/HeaderTabFlight.vue'),
  },
  computed: {
    tabIndex: {
      get() { return this.$store.getters['app-flight-v2/getTabIndex'] },
      set(newValue) { this.$store.dispatch('app-flight-v2/setTabIndex', newValue) },
    },
  },
  setup() {
    const {
      FLIGHT_APP_STORE_MODULE_NAME, getLoading: showLoading, statusJobDone, getSearchFlightArray,
    } = useBookingHandle()

    const resultSearchFlight = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getResultSearchFlight`])
    const getGroupedItineraryResponse = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getGroupedItineraryResponse`])

    const tempSelectedTrips = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedTrip`])
    const selectedTrips = ref(
      tempSelectedTrips.value.length ? tempSelectedTrips.value : [...getSearchFlightArray.value.map(() => null)],
    )

    // for ModalQuote
    const itineraryToRenders = ref([[]])
    const isRenderArrays = ref([[]])

    function clearSelectedTrip() {
      const data = [...getSearchFlightArray.value.map(() => null)]
      selectedTrips.value = data
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDraftSelectedTripArray`, data)
    }

    // Reset selected trips
    watch(() => [tempSelectedTrips, getSearchFlightArray], () => {
      if (getSearchFlightArray.value.length) {
        clearSelectedTrip()
      }
    }, { deep: true, immediate: true })

    // for combination
    const combinationSelectedTrip = computed(() => store.getters['app-flight-v2/getCombinationSelectedTrip'])
    const isCombination = computed(() => store.getters['app-flight-v2/getGroupedItineraryResponse'])
    const resultCombinationSearchFlight = computed(() => store.getters['app-flight-v2/getResultCombinationSearchFlight'])
    const combinationTripList = ref([])
    const combinationTripListForPriceReport = computed(() => resultCombinationSearchFlight.value.map(trip => {
      const airline = trip.airline
      const journeys = trip.journeys.filter(j => trip.bestCombinationFare.journeyIds.includes(j.journeyId))
        .map(j => j.segments.map(s => `${resolveAirlineFlightNumber(s.operating || s.airline, s.flightNumber)} ${convertISODateTime(s.departure.at, s.departure.timeZone || s.departure.timezone).time}`).join(' | '))
      const fare = combinateFareOptions(trip.journeys.map(j => j.fareOptions))
      return { airline, journeys, fare: fare.totalAdultModified }
    }))

    const sourcesFilter = computed(() => store.getters['app-flight-v2/getFilterBySources'])
    const airlinesFilter = computed(() => store.getters['app-flight-v2/getFilterByAirlines'])
    const stopPointsFilter = computed(() => store.getters['app-flight-v2/getFilterByStopPoints'])
    const transitPointsFilter = computed(() => store.getters['app-flight-v2/getFilterByTransitPoints'])
    const pricesFilter = computed(() => store.getters['app-flight-v2/getFilterByPrices'])
    const pricesFilterInit = computed(() => store.getters['app-flight-v2/getFilterPrices'])
    const durationsFilter = computed(() => store.getters['app-flight-v2/getFilterByDurations'])
    const departureTimeFilter = computed(() => store.getters['app-flight-v2/getFilterByDepartureTime'])
    const airCraftsFilter = computed(() => store.getters['app-flight-v2/getFilterByAirCrafts'])
    const fareTypeBusiness = computed(() => store.getters['app-flight-v2/getFilterByFareTypeBusiness'])
    watchEffect(() => {
      combinationTripList.value = resultCombinationSearchFlight.value.filter(trip => {
        if (airlinesFilter.value.length) {
          const tripAirlines = new Set()
          trip.journeys.forEach(journey => journey.segments.forEach(segment => {
            tripAirlines.add(segment.airline)
          }))
          if (!airlinesFilter.value.some(item => Array.from(tripAirlines).includes(item))) return false
        }
        if (sourcesFilter.value.length) {
          if (!sourcesFilter.value.some(item => trip.source === item)) return false
        }
        if (stopPointsFilter.value.length) {
          const tripStopPoints = new Set()
          trip.journeys.forEach(journey => tripStopPoints.add(journey.segments.length - 1))
          if (!stopPointsFilter.value.some(item => Array.from(tripStopPoints).includes(item))) return false
        }
        if (transitPointsFilter.value.length) {
          const tripTransitPoints = new Set()
          trip.journeys.forEach(journey => journey.stopPoint.split(' | ').forEach(sp => tripTransitPoints.add(sp)))
          if (!transitPointsFilter.value.some(item => Array.from(tripTransitPoints).includes(item))) return false
        }
        if (airCraftsFilter.value.length) {
          const tripAirCrafts = new Set()
          trip.journeys.forEach(journey => journey.segments.forEach(segment => {
            tripAirCrafts.add(segment.airCraft)
          }))
          if (!airCraftsFilter.value.some(item => Array.from(tripAirCrafts).includes(item))) return false
        }
        if (departureTimeFilter.value.length) {
          const tripDepartureTime = new Set()
          trip.journeys.forEach(journey => tripDepartureTime.add(moment.utc(journey.departure.at).utcOffset(journey.departure.timezone)))
          if (!Array.from(tripDepartureTime).some(parsedTime => parsedTime.hours() * 60 + parsedTime.minutes() >= departureTimeFilter.value[0]
            && parsedTime.hours() * 60 + parsedTime.minutes() <= departureTimeFilter.value[1])) return false
        }
        if (durationsFilter.value.length) {
          const tripDuration = new Set()
          trip.journeys.forEach(journey => tripDuration.add(journey.duration))
          if (!Array.from(tripDuration).some(duration => duration > durationsFilter.value[0] && duration < durationsFilter.value[1])) return false
        }
        if (fareTypeBusiness.value) {
          if (!trip.fareOptions.length) return false
          const tripFareOptions = new Set()
          trip.fareOptions.forEach(fareOption => fareOption.fareType.split(' | ').forEach(i => tripFareOptions.add(i)))
          if (!BUSINESS_FILTER_REGEX.test(Array.from(tripFareOptions).join(' '))) return false
        }
        if (pricesFilter.value.length) {
          if (!trip.fareOptions.length
            && (pricesFilter.value[0] === pricesFilterInit.value[0])
            && (pricesFilter.value[1] === pricesFilterInit.value[1])) {
            return true
          }
          const totalAdultModifiedAfterMultiple = trip.journeys.filter(j => trip.bestCombinationFare.journeyIds.includes(j.journeyId)).reduce((a, c) => a + c.fareOptions.afterMultiple.totalAdultModified, 0)
          if (totalAdultModifiedAfterMultiple < pricesFilter.value[0] || totalAdultModifiedAfterMultiple > pricesFilter.value[1]) {
            return false
          }
        }
        return true
      })
    })
    return {
      selectedTrips,
      combinationSelectedTrip,
      isCombination,
      getSearchFlightArray,
      showLoading,
      statusJobDone,
      resultSearchFlight,

      convertISODateTime,
      toHoursAndMinutes,
      formatCurrency,
      isEmpty,

      // for ModalQuote
      itineraryToRenders,
      isRenderArrays,

      clearSelectedTrip,
      getGroupedItineraryResponse,
      combinationTripList,
      combinationTripListForPriceReport,
    }
  },
}
</script>

<style lang="scss" scoped>
.ps-customizer-area {
  height: calc(100% - 83px);
}

::v-deep .p-0 .card-body {
  padding: 0 !important;
}

#result-component ::v-deep {
  .nav-wrapper-class {
    display: block;
    display: none; // FIXME - DISABLE
    position: sticky;
    top: 45px;
    z-index: 10;
    background: rgba(#f8f8f8, 0.8);
    backdrop-filter: blur(5px);
    margin: 0 0 1rem 0;
    border-radius: 8px;

    .nav-pills {
      margin: 0;
    }
  }
  .tab-title .nav-item  {
    width: 240px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-link.active {
      background: #DDF3FF;
      height: 100%;
      width: 100%;
      color: #005A8C;
      border: none;
    }
  }
}
</style>
