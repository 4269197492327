var render = function () {
  var _vm$combinationSelect, _vm$combinationSelect2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "result-component"
    }
  }, [_c('ProgressBar'), _c('b-overlay', {
    attrs: {
      "show": _vm.showLoading || _vm.isEmpty(_vm.resultSearchFlight) && !_vm.statusJobDone,
      "spinner-variant": "warning",
      "spinner-type": "grow",
      "spinner-small": "",
      "rounded": "sm"
    }
  }, [_c('div', [_c('HeaderTabFlight'), _c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "tab-title",
      "nav-wrapper-class": "nav-wrapper-class"
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_vm._l(_vm.resultSearchFlight, function (itinerary, indexItinerary) {
    return _c('b-tab', {
      key: indexItinerary
    }, [_c('ItineraryTab', {
      attrs: {
        "itinerary": itinerary,
        "index-itinerary": indexItinerary,
        "selected-trip": _vm.selectedTrips[indexItinerary]
      },
      on: {
        "update:selectedTrip": function updateSelectedTrip($event) {
          return _vm.$set(_vm.selectedTrips, indexItinerary, $event);
        },
        "update:selected-trip": function updateSelectedTrip($event) {
          return _vm.$set(_vm.selectedTrips, indexItinerary, $event);
        },
        "update:itineraryToRender": function updateItineraryToRender($event) {
          return _vm.itineraryToRenders[indexItinerary] = $event;
        },
        "update:isRenderArray": function updateIsRenderArray($event) {
          return _vm.isRenderArrays[indexItinerary] = $event;
        },
        "clear": _vm.clearSelectedTrip
      }
    })], 1);
  }), _vm.getGroupedItineraryResponse ? _c('b-tab', {
    attrs: {
      "active": _vm.getGroupedItineraryResponse
    }
  }, [_c('CombinationTab', {
    attrs: {
      "is-loading": !_vm.statusJobDone,
      "selected": _vm.combinationSelectedTrip,
      "render-list": _vm.combinationTripList
    },
    on: {
      "clear": _vm.clearSelectedTrip
    }
  })], 1) : _vm._e()], 2)], 1), _vm.isEmpty(_vm.resultSearchFlight) && _vm.statusJobDone ? _c('div', [_c('b-alert', {
    staticClass: "mb-50 p-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.notBookingFound')) + " ")])], 1) : _vm._e(), _c('Footer', {
    attrs: {
      "selected-trips": _vm.selectedTrips,
      "disabled-copy-price": _vm.showLoading || !_vm.statusJobDone || _vm.isEmpty(_vm.resultSearchFlight)
    },
    on: {
      "update:selectedTrips": function updateSelectedTrips($event) {
        _vm.selectedTrips = $event;
      },
      "update:selected-trips": function updateSelectedTrips($event) {
        _vm.selectedTrips = $event;
      }
    }
  }), !(_vm.showLoading || !_vm.statusJobDone || _vm.isEmpty(_vm.resultSearchFlight)) ? _c('ModalQuote', {
    attrs: {
      "segment-tab-index": _vm.tabIndex,
      "selected-trips": _vm.selectedTrips,
      "combination-selected-trip": (_vm$combinationSelect = (_vm$combinationSelect2 = _vm.combinationSelectedTrip) === null || _vm$combinationSelect2 === void 0 ? void 0 : _vm$combinationSelect2.journeys) !== null && _vm$combinationSelect !== void 0 ? _vm$combinationSelect : [],
      "title-arr": _vm.getSearchFlightArray,
      "trips-arr": _vm.itineraryToRenders,
      "is-render-trips-arr": _vm.isRenderArrays,
      "is-combination": _vm.isCombination,
      "combination-trip-list": _vm.combinationTripListForPriceReport
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }